import './style.scss';

function AccessibilityStatement() {
  return (
    <div className="accessibility-statement">
      <div className="content">
        <h1>הצהרת נגישות</h1>
        <p>  ערכי הנגשת השירות המבנים והסביבה הם נר לרגלינו. יישומם שזור בפעילותנו. </p>
        <p>אנו פועלים תדיר להדרכת עובדינו במתן שירות נגיש מכיל ומכבד ע"ב לומדות ונהלים ייעודים מעודכנים לצד ביצוע ביקורות שוטפות.</p>
        <p>בנוסף, אנו מקפידים על העסקה הוגנת, מגוונת ופלורליסטית ומתן הזדמנויות שוות לכלל עובדינו. מבנה הוילה מונגש והעמוד הייעודי של ריזורט דן אכדיה הצמוד לנו (באתר מלונות דן) כולל פירוט הסדרי נגישות רבים. </p>
        <p>האתר עומד בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), התשע"ג 2013 ותקן הישראלי ת"י 5568. כמו כן בוצעה התאמה לדרישות הנגישות של ארגון W3C לרמה 2 (AA) ונבנה לפי התקן של W3C ל- CSS ו .HTML. האתר מותאם לתצוגה בדפדפנים שונים (כרום, מוזילה, edge) לרבות שימוש בטלפון הסלולרי. האתר נבדק בקורא מסך מסוג NVDA  בגרסתו העדכנית ביותר. כל הפעולות באתר ניתנות לביצוע באמצעות מקלדת באופן הבא: לחיצה על מקש Tab מעבירה לקישור הבא ולחיצה על מקש Enter מפעילה את הקישור. לחיצה על Shift-Tab מעבירה לקישור הקודם. התאמות למשתמשים עיוורים – האתר מותאם לגולשים המשתמשים בתוכנות קורא מסך. האתר כולל:</p>
        <ul>
          <li>כותרות</li>
          <li>טקסטים חלופיים לתמונות</li>
          <li>חלוקת האתר לאזורי ניווט ואזור תוכן מרכז ואפשרות לדילוג ביניהם</li>
          <li>שימוש ב ARIA לשיפור יכולת הפרוש של קורא המסך</li>
        </ul>
        <p> יודגש, כי אדם עם מוגבלות (על פי חוק שוויון זכויות לאנשים עם מוגבלות, תשנ"ח-1998) יכול לפנות אלינו באופן ישיר בכדי שנסייע בהקראת והנגשת המידע המצוי באתר וכל מידע פומבי נוסף, לרבות דיווחי החברה באתר ההפצה של רשות ניירות ערך ("המגנ"א") ובמערכת אינטרנט להודעות החברות של הבורסה לניירות ערך בתל אביב בע"מ ("המאי"ה"). ניתן לפנות ישירות לרכזת הנגישות בחברה בטלפון 0547529802 או ב- 035203918 או בהגשת פניה /שאלה/בירור/ סיוע בדוא"ל: meital.barnea@danhotels.com .</p>
        <p>אכפת לנו! למרות שאנו משקיעים מאמצים רבים להנגשת האתר, תוך הסתייעות באנשי מקצוע ייחודיים לנושא זה, יתכן שעדיין ימצאו דפים או תבניות באתר אשר נדרש לשפרם מבחינת הנגישות. אם במהלך הגלישה באתר נתקלתם בבעיה ו/או קושי, נבקשכם להודיע לנו בהקדם על מנת שנוכל להמשיך, לשפר ולהעמיק את הנגישות לכלל אורחינו.</p>
        <p>הנגשת שירות תיעשה לבקשת אורח גם באמצעות הקראה, הודעת טקסט (sms) ובכל אמצעי אחר שיידרש.</p>
        <p>בנוסף, בכל שאלה ו/או בירור ניתן לפנות לרכזת הנגישות בחברה - עו"ד מיטל ברנע, בנייד: 0547529802 ובדוא"ל  meital.barnea@danhotels.com.</p>
        <p><strong>אנו מחויבים ליישום עקרונות הליבה שלעיל ושזירתם באופן שוטף בכל ענפי פעילותנו ונשמח לקבל תגובות והערות במטרה תמידית לשיפור וקידום חווית האירוח בוילה ובכלל. </strong></p>
      </div>
    </div>
  );
}

export default AccessibilityStatement;
