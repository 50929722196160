import React from 'react';
import { ReactComponent as SvgComponent } from '../../assets/images/logo.svg';
import './style.scss'

export default function Logo(props) {
  return <div className="app-logo">
    <h1 className="visually-hidden">Villa Accadia Beach Club</h1>
    <SvgComponent aria-label="logo villa accadia built 1977 est 2022" fill={props.color || '#fff'} />
  </div>
}
