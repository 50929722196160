import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './containers/Home';
import Register from './containers/Register';
import Payment from './containers/Payment';
import CreditguardSuccess from './containers/CreditguardSuccess';
import CreditguardError from './containers/CreditguardError';
import Success from './containers/Success';
import Contact from './containers/Contact';
import AccessibilityStatement from './containers/AccessibilityStatement';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/creditguard" element={<CreditguardSuccess />} />
        <Route path="/error" element={<CreditguardError />} />
        <Route path="/success" element={<Success />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/accessibilityStatement" element={<AccessibilityStatement />} />
      </Routes>
    </Router>
  );
}

export default App;
