import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Loader from '../../components/Loader';
import Select from "react-select";
import { formFields, formValidationSchema, handleSubmit, BirthdayInput, FocusToError } from './formData';
import countriesList from '../../Utils/countries.json';

function ContactForm({ setStep }) {
  return (
    <div className="contact-form">
      <Formik
        initialValues={formFields}
        validationSchema={formValidationSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          handleSubmit(values, { setSubmitting, setStatus }, setStep);
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting, status, values, setFieldValue, errors, setTouched, setErrors, handleChange }) => {
          return (
            <Form noValidate={true}>
              <div className="mandatory-fields">fields marked with * are mandatory</div>
              <div className="field-wrapper">
                <label htmlFor="firstName">*First Name</label>
                <Field type="text" id="firstName" name="firstName" placeholder="*First Name" aria-describedby="firstNameError" required onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} />
                <div className="error-wrapper" id="firstNameError"><ErrorMessage name="firstName" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="lastName">*Last Name</label>
                <Field type="text" id="lastName" name="lastName" placeholder="*Last Name" aria-describedby="lastNameError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} required />
                <div className="error-wrapper" id="lastNameError"><ErrorMessage name="lastName" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="phone">*Phone Number</label>
                <Field type="tel" id="phone" name="phone" placeholder="*Phone Number" aria-describedby="phoneError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} required />
                <div className="error-wrapper" id="phoneError"><ErrorMessage name="phone" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="email">*Email</label>
                <Field type="email" id="email" name="email" placeholder="*Email" aria-describedby="emailError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} required />
                <div className="error-wrapper" id="emailError"><ErrorMessage name="email" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="birthday">*Date of Birth (dd/mm/yyyy)</label>
                <Field id="birthday" name="birthday" component={BirthdayInput} placeholder="*Date of Birth (dd/mm/yyyy)" aria-describedby="birthdayError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} required />
                <div className="error-wrapper" id="birthdayError"><ErrorMessage name="birthday" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="country">*Residency</label>
                <Field id="country" name="country" aria-describedby="countryError" required>
                  {() => (
                    <Select
                      menuPlacement="top"
                      id="country"
                      filterOption={(option, input) => option.label.toLowerCase().startsWith(input.toLowerCase())}
                      getOptionLabel={(option) => option.label_en}
                      placeholder="*Residency"
                      options={countriesList}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      classNames={{
                        container: () => 'react-select-container',
                        control: () => 'react-select-control',
                        valueContainer: () => 'react-select-value-container',
                        input: () => 'react-select-input'
                      }}
                      value={countriesList.find(
                        (option) => option.value === values.country
                      )}
                      onChange={(option) => {
                        setTouched({});
                        setErrors({});
                        setFieldValue("country", option.value);
                      }}
                    />
                  )}
                </Field>
                <div className="error-wrapper" id="countryError"><ErrorMessage name="country" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="referrer">*Referrer</label>
                <Field type="text" id="referrer" name="referrer" placeholder="*Referrer" aria-describedby="referrerError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} required />
                <div className="error-wrapper" id="referrerError"><ErrorMessage name="referrer" /></div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="comment">Anything Else to Include?</label>
                <Field type="text" id="comment" name="comment" as="textarea" rows={4} placeholder="Anything Else to Include?" aria-describedby="commentError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} />
                <div className="error-wrapper" id="commentError"><ErrorMessage name="comment" /></div>
              </div>
              <div className="field-wrapper field-terms">
                <label htmlFor="terms">
                  <Field type="checkbox" id="terms" name="terms" aria-describedby="termsError" onChange={(e) => { setTouched({}); setErrors({}); handleChange(e); }} required />
                  {' '}
                  I Agree to the <a target="_blank" href="https://www.danhotels.com/AboutDanhotels/PrivacyPolicy">Privacy Policy</a>
                </label>
                <div className="error-wrapper" id="termsError"><ErrorMessage name="terms" /></div>
              </div>
              <div>
                <button className="contact-btn" type="submit" disabled={isSubmitting}>
                  {!isSubmitting ? 'SEND' : <Loader color="353D2F" />}
                </button>
                {status && <div className="status-wrapper error-submit-wrapper">{status}</div>}
              </div>
              <FocusToError isSubmitting={isSubmitting} errors={errors} />
            </Form>
          )
        }}
      </Formik>
    </div>
  );
}

export default ContactForm;
