import * as Yup from 'yup';
import axios from 'axios';
import config from '../../config';
import React, { useEffect } from 'react';

export const formFields = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  referrer: '',
  birthday: '',
  country: '',
  terms: false
}
export const formValidationSchema = Yup.object({
  firstName: Yup.string().matches(/^[a-zA-Z\s]+$/, 'only english').required('please enter your first name'),
  lastName: Yup.string().matches(/^[a-zA-Z\s]+$/, 'only english').required('please enter your last name'),
  phone: Yup.string().matches(/^[\d-]+$/, 'only number').required('please enter your phone'),
  email: Yup.string().email('Invalid email').required('please enter your email'),
  referrer: Yup.string().matches(/^[a-zA-Z\s]+$/, 'only english').required('please enter your Referrer'),
  birthday: Yup.string().matches(
    /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
    'invalid date'
  ).test('valid-year', 'the year must be greater than 1970', (value) => {
    if (!value) return true; // Allow empty value
    const year = Number(value.slice(6, 10));
    return year > 1900;
  }).required('please enter your birthday'),
  country: Yup.string().required('please enter your country'),
  terms: Yup.boolean().oneOf([true], 'the Privacy Policy must be accepted')
})

export function handleSubmit(values, { setSubmitting, setStatus }, setStep) {
  setSubmitting(true);
  axios.post(`${config.api.url}/contact/send-mail`, {
    ...values
  })
  .then((response) => {
    if (response.data.error) {
      setSubmitting(false)
      setStatus(response.data.error);
    } else {
      setSubmitting(false)
      setStep(2)
    }
  })
    .catch((error) => {
      setStatus("network error");
      setSubmitting(false)
    })
}

export const BirthdayInput = ({ field, form, placeholder }) => {
  const handleChange = event => {
    const { name, value } = event.target;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    if (value.length < form.values[name].length) {
      formattedValue = value;
    } else if (sanitizedValue.length <= 1) {
      formattedValue = sanitizedValue;
    } else if (sanitizedValue.length <= 3) {
      formattedValue = `${sanitizedValue.slice(0, 2)}/${sanitizedValue.slice(2)}`;
    } else {
      formattedValue = `${sanitizedValue.slice(0, 2)}/${sanitizedValue.slice(2, 4)}/${sanitizedValue.slice(4, 8)}`;
    }
    form.setFieldValue(name, formattedValue);
  };
  return (
    <input {...field} onChange={handleChange} placeholder={placeholder} />
  );
};

export function FocusToError({ isSubmitting, errors }) {
  useEffect(() => {
    if (isSubmitting && errors) {
      let firstErrorMessage;
      document.querySelectorAll('form .error-wrapper').forEach((element) => {
        if (!firstErrorMessage && element.textContent) { firstErrorMessage = element }
      });
      if (firstErrorMessage) {
        let errorElement = firstErrorMessage.previousElementSibling;
        if (errorElement.tagName != 'INPUT')
          errorElement = errorElement.getElementsByTagName('input')[0];
        errorElement.focus()
      }
    }
  }, [isSubmitting, errors]);
  return null;
}
